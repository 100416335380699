<template>
    <v-form @submit.prevent="send" ref="form" :disabled="loading">
        <v-card :loading="loading">
            <v-toolbar flat>
                <v-toolbar-title>Редактирование</v-toolbar-title>
                <v-spacer />
                <v-btn icon exact :to="{ name: 'AuthorList' }">
                    <v-icon>mdi-arrow-up</v-icon>
                </v-btn>
            </v-toolbar>
            <v-card-text>
                <v-row>
                    <v-col>
                        <v-text-field
                            label="Имя"
                            :rules="req"
                            v-model="name.ru"
                        />
                    </v-col>
                    <v-col>
                        <v-text-field
                            label="Имя [En]"
                            :rules="req"
                            v-model="name.en"
                        />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-textarea
                            label="Описание"
                            :rules="req"
                            v-model="description.ru"
                        />
                    </v-col>
                    <v-col>
                        <v-textarea
                            label="Описание [En]"
                            :rules="req"
                            v-model="description.en"
                        />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-file-input
                            label="Аватарка (300х300)"
                            :rules="id > 0 ? [] : req"
                            v-model="file"
                            accept="image/jpeg, image/png"
                        />
                    </v-col>
                    <v-col>
                        <img
                            v-if="id > 0"
                            class="rounded-circle d-block mx-auto"
                            width="300"
                            height="300"
                            :src="`${$store.state.uploads}/author/${id}.jpg?t=${mod}`"
                        />
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions>
                <v-spacer />
                <v-btn type="submit" text :disabled="loading" color="primary">
                    Сохранить
                </v-btn>
            </v-card-actions>
        </v-card>
        <file-uploader ref="uploader" />
    </v-form>
</template>

<script>
import FileUploader from "@c/FileUploader";
export default {
    name: "AuthorOne",
    components: {
        FileUploader,
    },
    props: {
        id: {
            type: Number,
            required: true,
        },
    },
    data: () => ({
        loading: false,
        name: {
            ru: null,
            en: null,
        },
        description: {
            ru: null,
            en: null,
        },
        file: null,
        mod: Date.now(),
    }),
    computed: {
        req: () => [(v) => !!v || "Заполните поле"],
    },
    mounted() {
        if (this.id > 0) {
            this.getData();
        }
    },
    methods: {
        async getData() {
            this.loading = true;
            const { name, description } = await this.$admin(
                `/author/${this.id}`
            );
            this.name = name;
            this.description = description;
            this.loading = false;
        },
        async send() {
            if (!this.$refs.form.validate()) {
                return;
            }
            const body = {
                id: this.id,
                name: this.name,
                description: this.description,
            };
            if (this.file) {
                const [file] = await this.$refs.uploader.upload([this.file]);
                body.file = file.tmp;
            }
            this.loading = true;
            const { id } = await this.$admin("/author", {
                method: "POST",
                body,
            });
            this.loading = false;
            this.file = null;
            this.mod = Date.now();
            if (this.id == 0) {
                this.$router.push({ name: this.$route.name, params: { id } });
            }
        },
    },
};
</script>
